import { useMutation } from "@tanstack/react-query";
import { Button } from "design-system/ui/atoms/button";
import { Input } from "design-system/ui/atoms/input/index";
import { Text } from "design-system/ui/atoms/texts/text";
import { ChevronRight, ContainerIcon, Mail, MoveLeftIcon, SquareCodeIcon } from "lucide-react";
import React, { useEffect, useRef, useState } from "react";
import { sendMagicLink } from "~/dataProcessor/api/auth";
import { useToast } from "~/design-system/atom/use-toast";
import { GithubCircleIcon, GoogleIcon } from "~/ui/constants/svg/homepage";
import { getCurrentClientSideHost } from "~/utils/global";
import GoogleLoginComponent, { useGithubLoginSelector, useGoogleLoginSelector } from "./components/GoogleLogin";
import { MainCardContainer, NonAuthScreenBackdrop } from "./layout";
// Move this to design system

const isEmailValid = (email: string) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

export const GoogleLoginButton = () => {
    const login = useGoogleLoginSelector();
    return (
        <Button className="flex w-full items-center justify-center gap-[8px]" size={"44"} variant="secondary_outline" onClick={login}>
            <GoogleIcon height={16} width={16} /> Continue with Gmail
        </Button>
    );
};

export function LoginBox() {
    const githubLogin = useGithubLoginSelector();
    const inputRef = useRef<HTMLInputElement>(null);

    // on mount, focus on the input element
    useEffect(() => {
        if (inputRef.current) inputRef.current.focus();
    }, []);
    const { toast } = useToast();
    const [email, setEmail] = useState("");
    const [showVerificationScreen, setShowVerificationScreen] = useState(false);

    const { mutate: sendMagicLinkFn, isLoading } = useMutation({
        mutationFn: ({ email }: { email: string }) => sendMagicLink(email, `${getCurrentClientSideHost()}/verify`),
        onSuccess: () => setShowVerificationScreen(true),
        onError: () => {
            toast({
                title: "Error",
                description: "Something went wrong. Please try again later",
                variant: "destructive",
            });
        },
        retry: 3,
    });

    const handleGetMagicLink = async (e: React.SyntheticEvent) => {
        e.preventDefault();
        sendMagicLinkFn({
            email,
        });
    };

    const handleEmailChange = (newValue: string) => {
        setEmail(newValue);
    };

    const handleGoBack = () => {
        setShowVerificationScreen(false);
        requestAnimationFrame(() => {
            if (inputRef.current) inputRef.current.focus();
        });
    };

    if (showVerificationScreen) {
        return (
            <MainCardContainer heading="Verification email sent">
                <div className="mt-[32px] flex flex-col items-center justify-center gap-[24px] px-[20px] pt-[32px] ds-text-[14.5px] ds-text-center">
                    <Text size={"14.5"} className="leading-[28px]">
                        Verification email sent to{" "}
                        <Text weight="500" className=" underline">
                            {email}
                        </Text>
                        . Check your inbox and click on the link.
                    </Text>

                    <div className="flex items-center gap-[8px] hover:cursor-pointer hover:underline ds-text-13" onClick={handleGoBack}>
                        <MoveLeftIcon className="h-[16px] w-[16px] ds-text-black-800" />
                        Go back
                    </div>
                </div>
            </MainCardContainer>
        );
    }
    return (
        <MainCardContainer heading="Login to continue">
            <div className="mt-[32px] flex flex-col gap-[24px]">
                <div className="flex flex-col gap-[12px]">
                    <Button
                        className="flex w-full items-center justify-center gap-[8px]"
                        size={"44"}
                        variant="secondary"
                        onClick={() => {
                            githubLogin();
                        }}
                    >
                        <GithubCircleIcon /> Continue with Github
                    </Button>

                    <GoogleLoginComponent>
                        <GoogleLoginButton />
                    </GoogleLoginComponent>
                </div>

                <div className="relative flex items-center justify-center">
                    <hr className="h-[.5px] w-full ds-bg-white-500" />
                    <span className="px-[8px] ds-bg-white ds-text-black-800 ds-text-12">or</span>
                    <hr className="h-[.5px] w-full ds-bg-white-500" />
                </div>

                <div className="flex flex-col gap-[12px]">
                    <div className="relative">
                        <Input
                            placeholder="name@company.com"
                            className="h-[42px] w-full pl-[34px]"
                            ref={inputRef}
                            value={email}
                            onChange={(e) => handleEmailChange(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    handleGetMagicLink(e);
                                }
                            }}
                        />
                        <Mail
                            className="absolute left-[12px] top-1/2 -translate-y-1/2 h-[16px] w-[16px] text-black-200"
                            strokeWidth={1.2}
                        />
                    </div>

                    <Button
                        className="w-full"
                        size={"44"}
                        variant={!isEmailValid(email) ? "disabled" : "primary"}
                        isLoading={isLoading}
                        onClick={handleGetMagicLink}
                    >
                        Continue with Email <ChevronRight className="h-[16px] w-[16px]" />
                    </Button>
                </div>
            </div>

            <div className="mt-[32px] flex justify-center gap-[20px] md:flex-col md:items-center md:pb-[20px] ds-text-black-800 ds-text-13">
                <div className="flex items-center gap-[8px] text-center">
                    <SquareCodeIcon className="h-[16px] w-[15px] ds-text-blue-600" />
                    <Text size={"14.5"} className="leading-[28px]">
                        Easy to use Python/TS SDK
                    </Text>
                </div>
                <div className="flex items-center gap-[8px]">
                    <ContainerIcon className="h-[16px] w-[16px] ds-text-orange-600" />
                    <Text size={"14.5"} className="leading-[28px]">
                        100+ integrations
                    </Text>
                </div>
            </div>
        </MainCardContainer>
    );
}

export function LoginScreen() {
    return (
        <NonAuthScreenBackdrop>
            <LoginBox />
        </NonAuthScreenBackdrop>
    );
}
