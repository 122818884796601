import clsx from "clsx";
import React from "react";

import { Backdrop } from "design-system/ui/atoms/layout/backdrop";
import { SquareLogo } from "design-system/ui/atoms/logo";
import { Heading } from "design-system/ui/atoms/texts/heading";
import { AppsFooter } from "./components/Marquee";

const Card = ({ children, className }: { children: React.ReactNode; className: string }) => {
    return (
        <div
            className={clsx(
                "w-full max-w-[440px] ds-bg-white p-[28px] pb-[40px] md:p-[16px] rounded-[20px] md:rounded-[0px] ds-border-half ds-border-white-400 shadow-[inset_0px_-4px_0px_0px_rgba(0,0,0,0.12)]",
                className,
            )}
        >
            {children}
        </div>
    );
};

export const MainCardContainer = ({ children, heading, className }: { children: React.ReactNode; heading: string; className?: string }) => {
    return (
        <Card className={clsx("z-10 mt-[3vh] min-h-[514px] !w-[440px] !max-w-[100%] ds-bg-white-50", className)}>
            <div className="mb-[16px] flex flex-col items-center justify-center gap-[16px] text-center">
                <SquareLogo size={40} monochrome={false} />
                <Heading type="h2" size="20px">
                    {heading}
                </Heading>
            </div>
            {children}
        </Card>
    );
};

export const NonAuthScreenBackdrop = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className="relative flex h-screen flex-col items-center justify-around">
            <div className="fixed inset-0 h-full w-full">
                <Backdrop />
            </div>

            <div className="flex flex-col items-center justify-center">{children}</div>

            <AppsFooter />
        </div>
    );
};

export function NonAuthLayout({
    children,

    heading,
}: {
    children: React.ReactNode;
    heading: string;
}) {
    return (
        <NonAuthScreenBackdrop>
            <MainCardContainer heading={heading}>{children}</MainCardContainer>
        </NonAuthScreenBackdrop>
    );
}
