import { Text } from "design-system/ui/atoms/texts/text";
import { MoveDown } from "lucide-react";
import Marquee from "react-fast-marquee";
import { apps } from "./constants";

export const AppBox = ({ icon, title, subtitle }: { icon: string; title: string; subtitle: string }) => {
    return (
        <div className="flex gap-[6px] ds-border-half ds-border-white-400 rounded-[12px] px-[16px] py-[14px] min-w-[fit-content] ds-bg-white-0 !pr-[40px]">
            <img src={icon} alt={title} className="w-[20px] h-[20px]" />
            <div className="flex flex-col ml-[4px] gap-[4px] leading-none">
                <Text type="p" size="14" weight="500" color="black-900" className="leading-[14px]">
                    {title}
                </Text>
                <Text type="p" size="11" weight="400" color="gray-600" className="leading-[11px]">
                    {subtitle}
                </Text>
            </div>
        </div>
    );
};

export const AppsFooter = () => {
    return (
        <div className="flex flex-col items-center z-[100]">
            <Text type="p" size="15" weight="400" color="black-600" className="text-center mb-[4px]">
                Give your agents access to real world
            </Text>
            <MoveDown className="mx-auto mb-[20px] ds-text-black-1000" strokeWidth={1.2} />
            <div className="max-w-[100vw] overflow-hidden">
                <Marquee gradient={false} speed={40}>
                    <div className="flex items-center gap-6 px-3">
                        {apps.map((app) => (
                            <AppBox key={app.title} icon={app.icon} title={app.title} subtitle={app.subtitle} />
                        ))}
                    </div>
                </Marquee>
            </div>
        </div>
    );
};
